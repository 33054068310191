<template>
  <div>
    <div v-if="content || isPreviewing()">
      <Content
        model="page"
        :can-track="true"
        :content="content"
        :api-key="builderApiKey"
        :custom-components="REGISTERED_COMPONENTS" />
    </div>
  </div>
</template>

<script setup>
import { Content, fetchOneEntry, isPreviewing } from "@builder.io/sdk-vue";
import B2BHubspotForm from "~/components/block/B2BHubspotForm.vue";
import FaqItems from "~/components/block/FaqItems.vue";
import Drawer from "~/components/builder.io/Drawer.vue";
import FaqItem from "~/components/builder.io/FaqItem.vue";
import BuilderFaqItemsById from "~/components/builder.io/FaqItemsById.vue";
import StarsRating from "~/components/StarsRating.vue";
import { useCustomHead } from "~/composables/useCustomHead";

// Register your Builder components
const REGISTERED_COMPONENTS = [
  {
    canHaveChildren: false,
    component: FaqItems,
    inputs: [
      {
        name: "faqItems",
        required: true,
        type: "object",
      },
      {
        name: "title",
        type: "text",
      },
    ],
    name: "BlockFaqItems",
  },
  {
    canHaveChildren: false,
    component: BuilderFaqItemsById,
    inputs: [
      {
        name: "faqItemIds",
        required: true,
        subFields: [
          {
            name: "id",
            type: "number",
          },
        ],
        type: "list",
      },
      {
        name: "title",
        type: "text",
      },
    ],
    name: "BuilderFaqItemsById",
  },
  {
    canHaveChildren: true,
    component: FaqItem,
    inputs: [
      {
        name: "question",
        required: true,
        type: "text",
      },
      {
        defaultValue: false,
        name: "isOpen",
        type: "boolean",
      },
    ],
    name: "FaqItem",
  },
  {
    canHaveChildren: true,
    component: Drawer,
    inputs: [
      {
        name: "id",
        required: true,
        type: "text",
      },
    ],
    name: "Drawer",
  },
  {
    canHaveChildren: false,
    component: StarsRating,
    inputs: [
      {
        name: "rating",
        required: true,
        type: "number",
      },
    ],
    name: "StarsRating",
  },
  {
    canHaveChildren: false,
    component: B2BHubspotForm,
    inputs: [
      {
        name: "formId",
        required: true,
        type: "text",
      },
    ],
    name: "HubspotForm",
  },
];

const config = useRuntimeConfig();
const builderApiKey = ref(config.public.builderApiToken);
const isDev = import.meta.dev;
// the app url is also set to haelsi.dev on preview environments
const isStaging = config.public.appUrl === "https://haelsi.dev";

const route = useRoute();

// fetch builder content data
const { data: content } = await useAsyncData("builderData", () =>
  fetchOneEntry({
    apiKey: builderApiKey.value,
    model: "page",
    options: {
      includeUnpublished: isPreviewing(route.query) || isDev || isStaging,
    },
    userAttributes: {
      urlPath: route.path,
    },
  }),
);

if (content.value === null) {
  throw createError({
    fatal: true,
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}

const variant = route.query.variant;
if (variant !== undefined) {
  const cookieVariant = `builder.tests.${content.value.id}`;
  const cookie = useCookie(cookieVariant, { sameSite: "none", secure: true });
  cookie.value = variant.toString();
}

useCustomHead({
  metaDescription: content.value.data.description,
  metaImage: {
    data: {
      attributes: {
        url: content.value.data.previewImage,
      },
    },
  },
  metaTitle: content.value.data.title,
});
</script>
