<template>
  <div class="mx-auto w-full max-w-screen-md">
    <div data-accordion="collapse" class="flex flex-col gap-1">
      <div :key="id" class="cursor-pointer px-3 transition ease-in-out md:px-6">
        <h2 v-if="question" :id="`accordion-flush-heading-${id}`">
          <button
            type="button"
            class="flex w-full items-center justify-between gap-x-2 bg-transparent py-4 text-left font-medium text-gray-900"
            :data-accordion-target="`#accordion-collapse-body-${id}`"
            :aria-expanded="isOpen"
            :aria-controls="`accordion-collapse-body-${id}`">
            <span class="text-xl font-medium leading-normal text-gray-900 md:text-2xl">
              {{ question }}
            </span>

            <span>
              <SvgoChevronRight class="h-4 w-4 rotate-90 md:h-6 md:w-6" />
            </span>
          </button>
        </h2>
        <div
          :id="`accordion-collapse-body-${id}`"
          :aria-labelledby="`accordion-collapse-heading-${id}`"
          :class="{ hidden: isOpen === false }">
          <div class="pb-5">
            <div class="faq--content mb-2 text-sm text-gray-900 md:text-base">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { initAccordions } from "flowbite";
import { onMounted } from "vue";

const id = useId();

withDefaults(
  defineProps<{
    question?: string;
    isOpen?: boolean;
  }>(),
  {
    isOpen: false,
  },
);

onMounted(() => {
  initAccordions();
});
</script>

<style scoped>
button[aria-expanded="true"] svg {
  @apply -rotate-90;
}
</style>
