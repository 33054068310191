<template>
  <BlockFaqItems :title="title" :faq-items="faqItems.data" />
</template>

<script setup lang="ts">
import { stringify } from "qs";
import type { FaqItem } from "~/types/strapi/api/faq-item/content-types/faq-item/faq-item";
import type { Payload } from "~/types/strapi/common/schemas-to-ts/Payload";

type FaqItemId = {
  id: number;
};

const props = defineProps<{
  faqItemIds?: FaqItemId[];
  title?: string;
}>();

const ids = computed(() => props.faqItemIds?.map((item) => item.id));
const query = stringify(
  {
    fields: ["question", "answer"],
    filters: {
      id: {
        $in: ids.value,
      },
    },
  },
  {
    encodeValuesOnly: true, // prettify URL
  },
);
const { data: faqItems } = await useApiFetch<Payload<FaqItem[]>>(`/api/faq-items?${query}`);
</script>

<style scoped></style>
